import { Box } from "@mui/material";
import LogoWithTitle from "@src/icons/png/LogoWithTitle.png";
import { ReactComponent as LogoBg } from "@src/icons/svg/LogoBg.svg";
import theme from "@src/styles/theme";
import MobileLogo from "@src/icons/png/Logo.png";

const classes = {
  root: {
    position: "relative",
  },
  mainArrow: {
    zIndex: 2,
    position: "relative",
    background: "white",
    padding: 0.25,
    width: 184,
    height: 161,
    clipPath: "polygon(0 0, 100% 0, 100% 68%, 50% 100%, 0 68%)",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  additionalArrow: {
    position: "absolute",
    zIndex: 1,
    left: -8,
    top: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileLogo: {
    width: 69,
    height: 58,
    position: "absolute",
    left: 20,
    top: 20,
  },
};

const Logo = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.mainArrow}>
        <Box>
          <img style={{ width: 172, height: 140 }} src={LogoWithTitle} alt="" />
        </Box>
      </Box>
      <Box sx={classes.additionalArrow}>
        <LogoBg />
      </Box>
      <Box sx={classes.mobileLogo}>
        <img style={{ width: "100%" }} src={MobileLogo} alt="" />
      </Box>
    </Box>
  );
};

export default Logo;
