import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "@src/styles/theme";
import { ReactComponent as MenuIcon } from "@src/icons/svg/menu.svg";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LogoIcon from "@src/icons/png/Logo.png";
import { sections } from "@src/constants/sections";

const classes = {
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    gap: 2,
    marginTop: 5.25,
  },
  btn: {
    textTransform: "uppercase",
    fontSize: 16,
    padding: theme.spacing(1, 0),
  },
  drawer: {
    "& .MuiPaper-root": {
      width: "100%",
      height: "100%",
      background: "transparent",
    },
    "& .MuiBackdrop-root": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  list: {
    marginTop: 4,
  },
  listItem: {
    display: "block",
  },
  listItemText: {
    color: theme.palette.common.white,
    textAlign: "center",
    fontWeight: 700,
    fontSize: 24,
    textTransform: "uppercase",
  },
  closeIconBtn: {
    color: theme.palette.common.white,
    fontSize: 32,
  },
  closeIconBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: 2,
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconBtn: {
    marginTop: "17px",
  },
};

const Navbar = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const mediaDownMd = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (!mediaDownMd) {
      setIsOpenMenu(false);
    }
  }, [mediaDownMd]);

  if (mediaDownMd) {
    return (
      <Box>
        <IconButton sx={classes.iconBtn} onClick={() => setIsOpenMenu(true)}>
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor={"top"}
          sx={classes.drawer}
          open={isOpenMenu}
          onClose={() => setIsOpenMenu(false)}
        >
          <Box sx={classes.closeIconBtnContainer}>
            <IconButton onClick={() => setIsOpenMenu(false)}>
              <CloseIcon sx={classes.closeIconBtn} />
            </IconButton>
          </Box>
          <Box sx={classes.logoContainer}>
            <img src={LogoIcon} alt="" />
          </Box>
          <List sx={classes.list}>
            {Object.values(sections).map((item, index) => (
              <a key={index} href={`#${item.link}`}>
                <ListItemButton
                  sx={classes.listItem}
                  onClick={() => setIsOpenMenu(false)}
                >
                  <Typography sx={classes.listItemText}>
                    {item.title}
                  </Typography>
                </ListItemButton>
              </a>
            ))}
          </List>
        </Drawer>
      </Box>
    );
  }

  return (
    <Box sx={classes.root}>
      {Object.values(sections).map((item, index) => (
        <a key={index} href={`#${item.link}`}>
          <Button variant={"text"} sx={classes.btn}>
            {item.title}
          </Button>
        </a>
      ))}
    </Box>
  );
};

export default Navbar;
