import { Box, Button, Container, Divider, Typography } from "@mui/material";
import theme from "@src/styles/theme";
import { sections } from "@src/constants/sections";
import Logo from "@src/icons/png/Logo.png";

const classes = {
  root: {
    marginTop: 25,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  contentContainer: {
    marginTop: 6.25,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 20,
    },
  },
  columnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "start",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      gap: 5,
    },
  },
  menuContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
    minWidth: 150,
    color: "#BFC0C1",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    gap: 2,
    minWidth: 120,
  },
  btn: {
    color: "#BFC0C1",
    textTransform: "uppercase",
    padding: 0,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  bottomTextContainer: {
    marginTop: 12.5,
    paddingBottom: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 6,
    },
  },
  menuPadding: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 2,
    },
  },
};

const FooterSection = () => {
  return (
    <Box sx={classes.root}>
      <Divider />
      <Container maxWidth={"lg"}>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.columnContainer}>
            <Box sx={classes.menuContainer}>
              {Object.values(sections).map((item, index) => (
                <a href={`#${item.link}`} key={index}>
                  <Button sx={classes.btn} variant={"text"}>
                    {item.title}
                  </Button>
                </a>
              ))}
            </Box>
            <Box sx={classes.menuContainer}>
              <Typography sx={{ color: "inherit" }} variant={"subtitle1"}>
                CONTACT US
              </Typography>
              <a href="mailto: social@cryptorim.io">
                <Typography sx={{ color: "inherit" }}>
                  social@cryptorim.io
                </Typography>
              </a>
              <a href="tel: +380676278500">
                <Typography sx={{ color: "inherit" }}>+380676278500</Typography>
              </a>
              {/*<Typography sx={{ color: "inherit" }}>Telegram</Typography>*/}
            </Box>
          </Box>
        </Box>
        <Box sx={classes.bottomTextContainer}>
          <Typography color={"#727171"}>
            All right reserved. Cryptorim 2022
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterSection;
