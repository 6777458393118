import { Box } from "@mui/material";
import TypographyGradient from "@components/TypographyGradient/TypographyGradient";
import goLogo from "@src/icons/techologyLogo/Go.png";
import angularLogo from "@src/icons/techologyLogo/Angular.png";
import nodeLogo from "@src/icons/techologyLogo/Node.png";
import blockChainLogo from "@src/icons/techologyLogo/BlockChain.png";
import solidityLogo from "@src/icons/techologyLogo/Solidity.png";
import reactLogo from "@src/icons/techologyLogo/React.png";
import web3Logo from "@src/icons/techologyLogo/Web3.png";
import theme from "@src/styles/theme";
import { sections } from "@src/constants/sections";

const classes = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 6.25,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  iconsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 8.5,
    },
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  rowContainerMobile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(0, 2.5, 0, 2.5),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "-webkit-center",
      width: "100%",
    },
  },
};

const OurTechnologySection = () => {
  return (
    <Box sx={classes.root} id={sections.technology.link}>
      <Box sx={classes.titleContainer}>
        <TypographyGradient variant={"h3"}>Our Technology</TypographyGradient>
      </Box>
      <Box sx={classes.iconsContainer}>
        <Box sx={classes.rowContainer}>
          <Box>
            <img src={solidityLogo} alt="" />
          </Box>
          <Box>
            <img src={reactLogo} alt="" />
          </Box>
          <Box>
            <img src={angularLogo} alt="" />
          </Box>
          <Box>
            <img src={blockChainLogo} alt="" />
          </Box>
          <Box>
            <img src={nodeLogo} alt="" />
          </Box>
          <Box>
            <img src={goLogo} alt="" />
          </Box>
          <Box>
            <img src={web3Logo} alt="" />
          </Box>
        </Box>

        <Box sx={classes.rowContainerMobile}>
          <Box sx={classes.mobileIconContainer}>
            <img src={angularLogo} alt="" />
          </Box>
          <Box sx={classes.mobileIconContainer}>
            <img src={reactLogo} alt="" />
          </Box>
        </Box>
        <Box sx={classes.rowContainerMobile}>
          <Box sx={classes.mobileIconContainer}>
            <img src={goLogo} alt="" />
          </Box>
          <Box sx={classes.mobileIconContainer}>
            <img src={nodeLogo} alt="" />
          </Box>
        </Box>
        <Box sx={classes.rowContainerMobile}>
          <Box sx={classes.mobileIconContainer}>
            <img src={web3Logo} alt="" />
          </Box>
          <Box sx={classes.mobileIconContainer}>
            <img src={solidityLogo} alt="" />
          </Box>
        </Box>
        <Box sx={classes.rowContainerMobile}>
          <Box sx={classes.mobileIconContainer}>
            <img src={blockChainLogo} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OurTechnologySection;
