import { Components, Theme } from "@mui/material";

const getOverridesComponent = (theme: Theme): Components => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderImage:
          "linear-gradient(240deg, #EC0BE5 34.69%, #FAC0F8 48.68%, #8DDE9B 60.72%, #28E249 78.06%)",
        borderImageSlice: 1,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        border: "none",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        color: theme.palette.common.white,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderStyle: "none",
      },
      root: {
        borderRadius: 0,
        border: `1px solid`,
        borderImage:
          "linear-gradient(240deg, #28E249 34.69%, #8DDE9B 48.68%, #FAC0F8 60.72%, #EC0BE5 78.06%)",
        borderImageSlice: 1,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        padding: theme.spacing(1.875, 6.25, 1.875, 6.25),
        textTransform: "none",
        fontSize: 18,
        lineHeight: "100%",
        fontWeight: "bold",
        letterSpacing: 0.3,
      },
      text: {
        color: theme.palette.common.white,
      },
      outlined: {
        color: theme.palette.common.white,
      },
      outlinedPrimary: {
        border: `1px solid ${theme.palette.common.white}`,
        "&:hover": {
          border: `1px solid`,
          borderImage:
            "linear-gradient(240deg, #28E249 34.69%, #8DDE9B 48.68%, #FAC0F8 60.72%, #EC0BE5 78.06%)",
          borderImageSlice: 1,
        },
        "&:active": {
          color: theme.palette.common.black,
          backgroundImage:
            "linear-gradient(240deg, #28E249 34.69%, #8DDE9B 48.68%, #FAC0F8 60.72%, #EC0BE5 78.06%)",
        },
      },
    },
  },
});

export default getOverridesComponent;
