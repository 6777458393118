import { createTheme, Theme } from "@mui/material";
import getOverridesComponent from "@src/styles/getOverridesComponent";

const defaultTheme: Theme = createTheme();

const baseTheme: Theme = createTheme({
  spacing: (x: number) => `${x * 8}px`,
  palette: {
    text: {
      secondary: "rgba(255,255,255,0.74)",
    },
  },
  typography: {
    fontFamily: "Metropolis, Arial, Serif",
    h1: {
      fontSize: 160,
      fontWeight: "bold",
      lineHeight: "90%",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: 86,
      },
    },
    h3: {
      fontSize: 54,
      fontWeight: "bold",
      lineHeight: "110%",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: 40,
      },
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: "bold",
      lineHeight: "16px",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: "bold",
      lineHeight: "16px",
    },
    allVariants: {
      fontSize: 16,
      color: "#ffffff",
      fontWeight: "normal",
    },
  },
});

const theme: Theme = createTheme({
  ...baseTheme,
  components: getOverridesComponent(baseTheme),
});

export default theme;
