import { Box } from "@mui/material";
import TypographyGradient from "@components/TypographyGradient/TypographyGradient";
import Duck from "@src/icons/partners/Duck.png";
import Europa from "@src/icons/partners/Europa.png";
import Lympo from "@src/icons/partners/Lympo.png";
import theme from "@src/styles/theme";
import { sections } from "@src/constants/sections";

const classes = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 6.25,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  iconsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 10,
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "-webkit-center",
      width: "100%",
    },
  },
};

const OurPartnersSection = () => {
  return (
    <Box sx={classes.root} id={sections.ourPartners.link}>
      <Box sx={classes.titleContainer}>
        <TypographyGradient variant={"h3"}>Our Partners</TypographyGradient>
      </Box>
      <Box sx={classes.iconsContainer}>
        <Box component={"a"} target={"_blank"} href={"https://duckstarter.io/"}>
          <img src={Duck} alt="" />
        </Box>
        <Box
          component={"a"}
          target={"_blank"}
          href={"https://www.europalabs.io/"}
        >
          <img src={Europa} alt="" />
        </Box>
        <Box component={"a"} target={"_blank"} href={"https://lympo.io/"}>
          <img src={Lympo} alt="" />
        </Box>
      </Box>
    </Box>
  );
};

export default OurPartnersSection;
