import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import theme from "@src/styles/theme";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import reCaptchaKey from "@src/constants/reCaptchaKey";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <div id={"recaptcha"} style={{ display: "none" }} />
    <GoogleReCaptchaProvider
      language={"en"}
      container={{ element: "recaptcha", parameters: { badge: "inline" } }}
      reCaptchaKey={reCaptchaKey}
    >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
