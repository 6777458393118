import LightCircle, { CircleColor } from "@components/LightCircle/LightCircle";

const LightCircles = () => {
  return (
    <>
      <LightCircle
        color={CircleColor.pink}
        dimensions={{ md: 248, sm: 248, xs: 150 }}
        top={{ xs: -87 }}
        left={{ xs: -68 }}
        filter={{ sm: 175, xs: 125 }}
      />
      <LightCircle
        color={CircleColor.pink}
        dimensions={{ md: 289, sm: 248, xs: 150 }}
        top={{ xs: 1000 }}
        right={{ xs: -200 }}
        filter={{ sm: 175, xs: 125 }}
      />
      <LightCircle
        color={CircleColor.green}
        dimensions={{ md: 289, sm: 248, xs: 150 }}
        top={{ xs: 1275 }}
        left={{ xs: -100 }}
        filter={{ sm: 200, xs: 150 }}
      />
      <LightCircle
        color={CircleColor.green}
        dimensions={{ md: 289, sm: 248, xs: 150 }}
        top={{ md: 2000, xs: 2500 }}
        right={{ xs: -100 }}
        filter={{ sm: 200, xs: 150 }}
      />
      <LightCircle
        color={CircleColor.pink}
        dimensions={{ md: 289, sm: 238, xs: 150 }}
        top={{ md: 2600, xs: 3200 }}
        left={{ xs: -170 }}
        filter={{ sm: 175, xs: 125 }}
      />
      <LightCircle
        color={CircleColor.green}
        dimensions={{ md: 289, sm: 248, xs: 150 }}
        top={{ md: 3300, xs: 4075 }}
        right={{ xs: -100 }}
        filter={{ sm: 200, xs: 150 }}
      />
      <LightCircle
        color={CircleColor.pink}
        dimensions={{ md: 289, sm: 238, xs: 150 }}
        top={{ xs: 4550 }}
        left={{ xs: -170 }}
        filter={{ sm: 175, xs: 125 }}
        display={{ xs: "block", md: "none" }}
      />
    </>
  );
};

export default LightCircles;
