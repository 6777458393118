import { Container } from "@mui/material";
import OurProjectsSection from "@pages/main/OurProjectsSection";
import MainSection from "@pages/main/MainSection";
import AboutUsSection from "@pages/main/AboutUsSection";
import OurTechnologySection from "@pages/main/OurTechnologySection";
import OurPartnersSection from "@pages/main/OurPartnersSection";
import ContactUs from "@pages/main/ContactUs";
import FooterSection from "@pages/main/FooterSection";
import LightCircles from "@pages/main/LightCircles";

const MainPage = () => {
  return (
    <>
      <Container maxWidth={"lg"}>
        <MainSection />
        <AboutUsSection />
        <OurProjectsSection />
        <OurTechnologySection />
        <OurPartnersSection />
        <ContactUs />
      </Container>
      <FooterSection />
      <LightCircles />
    </>
  );
};

export default MainPage;
