import { FC, ReactNode } from "react";
import { Modal } from "@mui/material";

const classes = {
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

type Props = {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
};

const AppModal: FC<Props> = ({ children, open, onClose }) => {
  return (
    <Modal sx={classes.root} open={open} onClose={onClose}>
      <>{children}</>
    </Modal>
  );
};

export default AppModal;
