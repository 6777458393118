import { Box, Button, Typography } from "@mui/material";
import TypographyGradient from "@components/TypographyGradient/TypographyGradient";
import aboutUsImg from "@src/icons/png/AboutUs.png";
import theme from "@src/styles/theme";
import { sections } from "@src/constants/sections";

const classes = {
  root: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: 25,
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      alignItems: "start",
      justifyContent: "space-between",
      gap: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      justifyContent: "center",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2.5,
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 335,
    },
  },
  titleContainer: {
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      textAlign: "-webkit-center",
    },
  },
  descriptionContainer: {
    textAlign: "start ",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  btnContainer: {
    marginTop: 3.75,
  },
  imgContainer: {
    [theme.breakpoints.down("md")]: {
      width: 250,
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  img: {
    [theme.breakpoints.down("md")]: {
      width: 250,
    },
  },
  btn: {
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  br: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
};

const AboutUsSection = () => {
  return (
    <Box sx={classes.root} id={sections.aboutUs.link}>
      <Box sx={classes.contentContainer}>
        <Box sx={classes.titleContainer}>
          <TypographyGradient variant={"h3"}>About Us</TypographyGradient>
        </Box>
        <Box sx={classes.descriptionContainer}>
          <Typography>
            We are a custom software development company.{" "}
            <Box sx={classes.br} component={"br"} />
            Thanks to our strong team of true tech, business, and marketing
            experts we helping businesses push their boundaries in solving daily
            tasks with cutting-edge business automation and blockchain
            solutions.{" "}
          </Typography>
        </Box>
        <Box sx={classes.btnContainer}>
          <a href={`#${sections.contactUs.link}`}>
            <Button sx={classes.btn} variant={"outlined"} color={"primary"}>
              {sections.contactUs.title}
            </Button>
          </a>
        </Box>
      </Box>
      <Box sx={classes.imgContainer}>
        <Box sx={classes.img} component={"img"} src={aboutUsImg} />
      </Box>
    </Box>
  );
};

export default AboutUsSection;
