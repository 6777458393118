import { Box } from "@mui/material";
import { FC, ReactElement, ReactNode } from "react";

const classes = {
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    position: "relative",
  },
  contentBox: {
    position: "absolute",
    width: "100%",
    border: "1px solid",
    borderImageSlice: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
  },
};

type Props = {
  children?: ReactNode;
  image?: ReactElement;
  rootStyles?: any;
  contentStyles?: any;
};

const GradientBlock: FC<Props> = ({
  children,
  image,
  contentStyles,
  rootStyles,
}) => {
  return (
    <Box sx={[classes.root, rootStyles]}>
      {image}
      <Box sx={[classes.contentBox, contentStyles]}>{children}</Box>
    </Box>
  );
};

export default GradientBlock;
