import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

const classes = {
  root: {
    clipPath: "polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)",
  },
  content: {
    clipPath: "polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

type Props = {
  children?: ReactNode;
  //width, height, padding, background
  rootStyles?: any;
  //width-padding*2, height-padding*2, background
  contentStyles?: any;
};

const Hexagon: FC<Props> = ({ children, rootStyles, contentStyles }) => {
  return (
    <Box sx={[classes.root, ...rootStyles]}>
      <Box sx={[classes.content, contentStyles]}>{children}</Box>
    </Box>
  );
};

export default Hexagon;
