import { Box } from "@mui/material";
import Hexagon from "@components/Hexagon/Hexagon";
import AppModal from "@components/Modal/AppModal";
import { FC, ReactNode } from "react";
import theme from "@src/styles/theme";
import { ReactComponent as ConfirmBg } from "@src/icons/svg/ConfirmBg.svg";

const classes = {
  root: {
    width: 480,
    height: 560,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: 320,
      height: 350,
    },
  },
  mainHexagon: {
    width: 480,
    height: 560,
    background:
      "linear-gradient(172deg, #F90AEE 0.01%, #002744 49.47%, #26D749 100%)",
    padding: "2px",
    position: "relative",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      width: 320,
      height: 350,
    },
  },
  mainHexagonContent: {
    width: 476,
    height: 556,
    background: "black",
    [theme.breakpoints.down("sm")]: {
      width: 316,
      height: 346,
    },
  },
  bg: {
    position: "absolute",
    left: -24,
    top: 44,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
};

type Props = {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
};

const HexagonPopup: FC<Props> = ({ children, open, onClose }) => {
  return (
    <AppModal open={open} onClose={onClose}>
      <Box sx={classes.root}>
        <Hexagon
          rootStyles={[classes.mainHexagon]}
          contentStyles={classes.mainHexagonContent}
        >
          {children}
        </Hexagon>
        <Box sx={classes.bg}>
          <ConfirmBg />
        </Box>
      </Box>
    </AppModal>
  );
};

export default HexagonPopup;
