import { Typography, TypographyProps } from "@mui/material";
import { FC, ReactNode } from "react";
import { Variant } from "@mui/material/styles/createTypography";

const classes = {
  typography: {
    width: "fit-content",
    background:
      "linear-gradient(240deg, #28E249 34.69%, #8DDE9B 48.68%, #FAC0F8 60.72%, #EC0BE5 78.06%)",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    boxDecorationBreak: "clone",
  },
};

type Props = {
  children: ReactNode;
  fontStyle?: TypographyProps;
  variant?: Variant;
  styles?: any;
};

const TypographyGradient: FC<Props> = ({
  children,
  variant,
  fontStyle,
  styles,
}) => {
  return (
    <Typography
      variant={variant}
      {...fontStyle}
      sx={[classes.typography, styles]}
    >
      {children}
    </Typography>
  );
};

export default TypographyGradient;
