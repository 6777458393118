import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Logo from "@components/Logo/Logo";
import mainImg from "@src/icons/png/MainImg.png";
import mainImgMobile from "@src/icons/png/MainImgMobile.png";
import Navbar from "@components/Navbar/Navbar";
import TypographyGradient from "@components/TypographyGradient/TypographyGradient";
import theme from "@src/styles/theme";
import { sections } from "@src/constants/sections";

const classes = {
  root: {
    height: 750,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  imgContainer: {
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    height: 161,
  },
  content: {
    width: 600,
    height: 465,
    marginTop: 7.25,
    marginLeft: "120px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: 520,
    },
    [theme.breakpoints.down("sm")]: {
      width: 335,
    },
  },
  textContainer: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    gap: 3,
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      gap: 2,
    },
  },
  btnContainer: {
    marginTop: 8.75,
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      marginTop: "160px",
      display: "block",
    },
  },
  btn: {
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: { sm: 140, md: 160 },
    height: { md: 300, sm: 270 },
  },
  titlePadding: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8px",
    },
  },
};

const MainSection = () => {
  const mediaDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={classes.root}>
      <Box sx={classes.header}>
        <Logo />
        <Navbar />
      </Box>
      <Box sx={classes.content}>
        <Box sx={classes.textContainer}>
          <TypographyGradient
            styles={classes.titlePadding}
            fontStyle={classes.title}
            variant={"h1"}
          >
            Crypto Diggers
          </TypographyGradient>
          <Typography fontSize={24}>Blockchain development services</Typography>
        </Box>
        <Box sx={classes.btnContainer}>
          <a href={`#${sections.contactUs.link}`}>
            <Button sx={classes.btn} variant={"outlined"} color={"primary"}>
              {sections.contactUs.title}
            </Button>
          </a>
        </Box>
      </Box>
      <Box sx={classes.imgContainer}>
        {mediaDownSm ? (
          <img src={mainImgMobile} alt="" />
        ) : (
          <img src={mainImg} alt="" />
        )}
      </Box>
    </Box>
  );
};

export default MainSection;
