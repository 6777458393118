import { Box, Button, TextField } from "@mui/material";
import TypographyGradient from "@components/TypographyGradient/TypographyGradient";
import theme from "@src/styles/theme";
import { sections } from "@src/constants/sections";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import HexagonPopup from "@components/Modal/HexagonPopup";
import SimplePopupContent from "@components/Modal/SimplePopupContent";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { BaseAPI } from "@src/services/BaseAPI";

const classes = {
  root: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 3.75,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  formContainer: {
    width: 530,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 1.25,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: 335,
    },
  },
  emailField: {
    width: "100%",
  },
  msgField: {
    width: "100%",
    height: 150,
  },
  btnContainer: {
    marginTop: 2.25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btn: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
};

const ContactUs = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);

  const onSubmit = async (values: { email: string; message: string }) => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("submit_contact_request").catch(
        (err) => console.log(err)
      );
      if (token) {
        BaseAPI.sendEmail(values.email, values.message, token)
          .then(() => {
            resetForm();
            setIsOpenConfirmModal(true);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    message: yup.string().min(10).max(1000).required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: onSubmit,
  });

  const { values, handleChange, touched, errors, handleSubmit, resetForm } =
    formik;

  return (
    <Box sx={classes.root} id={sections.contactUs.link}>
      <Box>
        <TypographyGradient variant={"h3"}>Contact Us</TypographyGradient>
      </Box>
      <Box
        sx={classes.formContainer}
        onSubmit={handleSubmit}
        component={"form"}
      >
        <TextField
          id={"email"}
          name={"email"}
          value={values.email}
          onChange={handleChange}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          placeholder={"Email address"}
          sx={classes.emailField}
        />
        <TextField
          id={"message"}
          name={"message"}
          value={values.message}
          onChange={handleChange}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
          rows={5}
          placeholder={"Your message"}
          multiline
          sx={classes.msgField}
        />
        <Box sx={classes.btnContainer}>
          <Button
            sx={classes.btn}
            type={"submit"}
            variant={"outlined"}
            color={"primary"}
          >
            Contact us
          </Button>
        </Box>
      </Box>
      <HexagonPopup
        open={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
      >
        <SimplePopupContent
          title={"Thank You!"}
          content={"Your message has been successfully sent."}
          subContent={"We will contact you soon!"}
        />
      </HexagonPopup>
    </Box>
  );
};

export default ContactUs;
