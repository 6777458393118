import { Box } from "@mui/material";
import { FC } from "react";

export enum CircleColor {
  "green" = "#2EFF49",
  "pink" = "#FF08F2",
}

type Props = {
  dimensions: { xs: number; sm?: number; md?: number };
  color: CircleColor;
  filter: { xs: number; sm?: number; md?: number };
  top?: { xs: number; sm?: number; md?: number };
  left?: { xs: number; sm?: number; md?: number };
  right?: { xs: number; sm?: number; md?: number };
  bottom?: { xs: number; sm?: number; md?: number };
  display?: { xs: string; sm?: string; md?: string };
};

const LightCircle: FC<Props> = ({
  filter,
  dimensions,
  color,
  top,
  left,
  right,
  bottom,
  display,
}) => {
  return (
    <Box
      sx={{
        width: dimensions,
        height: dimensions,
        background: color,
        filter: {
          xs: `blur(${filter.xs}px)`,
          sm: `blur(${filter.sm || filter.xs}px)`,
          md: `blur(${filter.md || filter.xs}px)`,
        },
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        position: "absolute",
        overflowBlock: "hidden",
        display: display,
        zIndex: -1,
      }}
    />
  );
};

export default LightCircle;
