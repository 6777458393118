import { FC, ReactNode } from "react";
import Hexagon from "@components/Hexagon/Hexagon";
import { Box } from "@mui/material";
import theme from "@src/styles/theme";

const classes = {
  root: {
    width: 93,
    height: 110,
    position: "relative",
  },
  mainHexagon: {
    width: 89,
    height: 102,
    padding: "1px",
    position: "relative",
    zIndex: 2,
  },
  mainHexagonContent: {
    width: 87,
    height: 100,
    background: "black",
  },
  bgImgContainer: {
    position: "absolute",
    zIndex: 1,
    left: -4,
    top: 8,
  },
};

type Props = {
  children: ReactNode;
  rootStyles?: any;
  icon?: ReactNode;
};

const HexagonIcon: FC<Props> = ({ children, rootStyles, icon }) => {
  return (
    <Box sx={classes.root}>
      <Hexagon
        rootStyles={[classes.mainHexagon, rootStyles]}
        contentStyles={classes.mainHexagonContent}
      >
        {children}
      </Hexagon>
      {icon && <Box sx={classes.bgImgContainer}>{icon}</Box>}
    </Box>
  );
};

export default HexagonIcon;
