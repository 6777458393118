import { Box, Typography } from "@mui/material";
import TypographyGradient from "@components/TypographyGradient/TypographyGradient";
import GradientBlock from "@components/GradienBlock/GradientBlock";
import { ReactComponent as DuckIcon } from "@src/icons/svg/Duck.svg";
import { ReactComponent as EuropaIcon } from "@src/icons/svg/Europa.svg";
import { ReactComponent as LympoIcon } from "@src/icons/svg/Lympo.svg";
import { ReactComponent as FirstBg } from "@src/icons/svg/OurProjectsFirstBg.svg";
import { ReactComponent as SecondBg } from "@src/icons/svg/OurProjectsSecondBg.svg";
import { ReactComponent as ThirdBg } from "@src/icons/svg/OurProjectsThirdBg.svg";
import HexagonIcon from "@components/Hexagon/HexagonIcon";
import theme from "@src/styles/theme";
import { sections } from "@src/constants/sections";

const classes = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    gap: 6.25,
    marginTop: 22,
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  projectsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 3.75,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "70px",
      minWidth: 335,
      maxWidth: 450,
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
    textAlign: "center",
    gap: 2,
    [theme.breakpoints.down("md")]: {
      marginTop: "32px",
    },
  },

  gradientRoot: {
    minHeight: 315,
    [theme.breakpoints.down("md")]: {
      minHeight: 250,
    },
  },
  topGradient: {
    minHeight: 260,
    top: 55,
    border: `1px solid`,
    borderImage: "linear-gradient(#F909EE 0, #010101 100%)",
    borderImageSlice: 1,
    borderBottom: 0,
    padding: theme.spacing(10.5, 3.75, 6.25, 3.75),
    [theme.breakpoints.down("md")]: {
      border: "none",
      top: 10,
    },
  },
  bottomGradient: {
    minHeight: 260,
    top: 55,
    border: `1px solid`,
    borderImage: "linear-gradient(#010101 0, #2AE142 100%)",
    borderImageSlice: 1,
    borderTop: 0,
    padding: theme.spacing(10.5, 3.75, 6.25, 3.75),
    [theme.breakpoints.down("md")]: {
      border: "none",
      top: 10,
    },
  },

  firstIconMainGradient: {
    background:
      "linear-gradient(310deg, #F90AEE 0.01%, #002744 49.47%, #26D749 100%)",
  },
  firstIconAdditionalGradient: {
    background:
      "linear-gradient(310deg, #26D749 0, #002744 52.61%, #F90AEE 100%)",
  },
  secondIconMainGradient: {
    background:
      "linear-gradient(172deg, #F90AEE 0.01%, #002744 49.47%, #26D749 100%)",
  },
  thirdIconMainGradient: {
    background:
      "linear-gradient(230deg, #F90AEE 0.01%, #002744 49.47%, #26D749 100%)",
  },
};

const OurProjectsSection = () => {
  const Duck = (
    <HexagonIcon rootStyles={classes.firstIconMainGradient} icon={<FirstBg />}>
      <DuckIcon />
    </HexagonIcon>
  );
  const Europa = (
    <HexagonIcon
      rootStyles={classes.secondIconMainGradient}
      icon={<SecondBg />}
    >
      <EuropaIcon />
    </HexagonIcon>
  );
  const Lympo = (
    <HexagonIcon rootStyles={classes.thirdIconMainGradient} icon={<ThirdBg />}>
      <LympoIcon />
    </HexagonIcon>
  );

  return (
    <Box sx={classes.root} id={sections.ourProjects.link}>
      <Box>
        <TypographyGradient variant={"h3"}>Our Projects</TypographyGradient>
      </Box>
      <Box sx={classes.projectsContainer}>
        <GradientBlock
          rootStyles={classes.gradientRoot}
          image={Duck}
          contentStyles={classes.topGradient}
        >
          <Box sx={classes.contentContainer}>
            <Typography fontSize={26}>DuckSTARTER</Typography>
            <Typography>
              DuckSTARTER is DuckDAO’s public token launchpad, a service
              platform for blockchain projects.
            </Typography>
          </Box>
        </GradientBlock>
        <GradientBlock
          rootStyles={classes.gradientRoot}
          image={Europa}
          contentStyles={classes.bottomGradient}
        >
          <Box sx={classes.contentContainer}>
            <Typography fontSize={26}>Europa Labs</Typography>
            <Typography>
              Europa provides a full-suite Web3 product engine for brands
              interested in adopting blockchain technology.
            </Typography>
          </Box>
        </GradientBlock>
        <GradientBlock
          rootStyles={classes.gradientRoot}
          image={Lympo}
          contentStyles={classes.topGradient}
        >
          <Box sx={classes.contentContainer}>
            <Typography fontSize={26}>Lympo</Typography>
            <Typography>
              Lympo is building the #1 sports NFTs ecosystem, including NFTs
              with IP rights of world-famous athletes and clubs.
            </Typography>
          </Box>
        </GradientBlock>
      </Box>
    </Box>
  );
};

export default OurProjectsSection;
