import { Box, Typography } from "@mui/material";
import TypographyGradient from "@components/TypographyGradient/TypographyGradient";
import React, { FC } from "react";
import theme from "@src/styles/theme";

const classes = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(0, 11, 0, 11),
    gap: 2,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 5, 0, 5),
    },
  },
};

type Props = {
  title: string;
  content: string;
  subContent?: string;
};

const SimplePopupContent: FC<Props> = ({ title, content, subContent }) => {
  return (
    <Box sx={classes.root}>
      <TypographyGradient variant={"h3"}>{title}</TypographyGradient>
      <Box>
        <Typography>{content}</Typography>
        {subContent && <Typography>{subContent}</Typography>}
      </Box>
    </Box>
  );
};

export default SimplePopupContent;
