import axios from "axios";
import { BASE_URL } from "@src/constants/BASE_URL";

const instance = axios.create({
  baseURL: BASE_URL,
});

export const BaseAPI = {
  sendEmail: (
    email: string,
    message: string,
    captchaData: string
  ): Promise<boolean> => {
    return instance.post("contact/request", { email, message, captchaData });
  },
};
