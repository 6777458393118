export enum Sections {
  aboutUs = "aboutUs",
  ourProjects = "ourProjects",
  technology = "technology",
  ourPartners = "ourPartners",
  contactUs = "contactUs",
}

export const sections: Record<Sections, { link: string; title: string }> = {
  [Sections.aboutUs]: {
    link: "aboutUs",
    title: "about us",
  },
  [Sections.ourProjects]: {
    link: "ourProjects",
    title: "our projects",
  },
  [Sections.technology]: {
    link: "technology",
    title: "technology",
  },
  [Sections.ourPartners]: {
    link: "ourPartners",
    title: "our partners",
  },
  [Sections.contactUs]: {
    link: "contactUs",
    title: "contact us",
  },
};
